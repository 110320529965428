<template>
  <div>
    <div class="container">
      <div class="null-title">无牌车入场</div>
      <div class="park-container">
        <div class="park-tips">当前车场</div>
        <div class="park-name">{{ park.parkname }}</div>
        <!--<div class="req-bnt">请求入场</div>-->
        <van-button type="info" size="large" @click="doIn">请求入场</van-button>
      </div>
      <div class="banner" v-if="!isCloseAD">
        <adver urls="501052411046072320"></adver>
      </div>
      <!-- 测试 弹窗广告 -->
      <!-- <div>
        <tanchuang :downAd="hideAd" :show="show"></tanchuang>
      </div> -->
    </div>
    <div class="dialog" v-show="visible.success">
      <div class="p_null_img_box">
        <img src="../../../assets/img/success.png" />
      </div>
      <div class="req-success-word" v-if="!visible.show_success_words">
        请求成功
      </div>
      <div
        class="req-success-word success"
        v-if="visible.show_success_words == 1"
      >
        开闸成功
      </div>
      <div class="req-success-word fail" v-if="visible.show_success_words == 2">
        开闸失败
      </div>
      <div class="req-success-p">
        <span>{{ vh_p_number }}</span
        ><span class="success-b">临时车牌</span>
      </div>
      <div class="success-tips-word">请待道闸成功开启后入场</div>
      <div class="banner" v-if="visible.success">
        <adver urls="744201726840659968"></adver>
      </div>
    </div>
    <div class="dialog" v-show="visible.fail">
      <div class="p_null_img_box">
        <img src="../../../assets/img/fail.png" />
      </div>
      <div class="req-fail-word">{{ park_req_msg }}</div>
      <div class="fail-btn-box">
        <van-button
          type="info"
          style="color: #4797ea; background: #edf5fd; border: none"
          size="large"
          @click="resetDoIn()"
          >重新请求
        </van-button>
      </div>
    </div>
    <!--<div class="dialog" v-show="visible.tishi">-->
    <!--<div class="p_null_img_box"><img src="../../src/assets/img/tishi.png"/></div>-->
    <!--<div class="tishi-line-1">无牌车离场</div>-->
    <!--<div class="tishi-line-2">请至通道内扫码支付离场</div>-->
    <!--<div class="tishi-btn-box">-->
    <!--<div>有牌车付款请点击缴费</div>-->
    <!--<div class="tishi-btn-inner"><van-button type="info" style="color:#4797EA;background: #EDF5FD;border: none;" size="large" @click=""-->
    <!--&gt;缴费</van-button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="dialog" v-show="visible.cry">-->
    <!--<div class="p_null_img_box"><img src="../../src/assets/img/cry.png"/></div>-->
    <!--<div class="qry-fail-word">查询失败</div>-->
    <!--<div class="qry-fail-word2">查询出现问题，请重新扫码</div>-->
    <!--<div class="tishi-btn-box">-->
    <!--<div>有牌车付款请点击缴费</div>-->
    <!--<div class="tishi-btn-inner"><van-button type="info" style="color:#4797EA;background: #EDF5FD;border: none;" size="large" @click=""-->
    <!--&gt;缴费</van-button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import axios from 'axios'
import * as api from '../../../api/api'
import * as util from '../../../api/util'
import * as common from '../../../api/common'
import * as mqtt from '../../../api/mqtt/wsconnect'
import adver from '../../../components/Adver.vue'
// import tanchuang from '../../../components/tanchuang.vue'

export default {
  name: 'vh_p_n_in',
  props: [],
  components: {
    adver: adver
    // tanchuang: tanchuang
  },
  data () {
    return {
      vh_p_number: '',
      park_req_msg: '请求失败',
      park: {
        total_spaces: 1,
        parkname: '车场'
      },
      visible: {
        success: false,
        fail: false,
        tishi: false,
        cry: false,
        show_success_words: 0
      },
      // add 广告
      show: false,
      isCloseAD: false
    }
  },

  methods: {
    resetDoIn () {
      this.doIn()
    },
    // 获取关闭广告配置
    queryADConfig () {
      const flag = util.judgeADClose({}, 2, 1)
      this.isCloseAD = flag
    },
    // add 广告
    // 关闭弹窗
    hideAd () {
      // this.show = false;
    },
    // 显示弹窗
    turnOnAd () {
      this.show = true
    },
    async init_mqtt () {
      const client = await mqtt.getClient()
      if (!client.connected) {
        // this.$toast.clear()
        this.$toast.fail('NetWork err!')
        throw (new Error('NetWork err!'))
      } else {
        return client
      }
    },
    async subscribe (action) {
      const topic = `/ypt/u_${this.$route.query.sid}/${encodeURIComponent(
        this.vh_p_number
      )}`
      const options = {
        payload: {
          topic: action
        }
      }
      await mqtt.subscribe(topic, options)
    },

    getResult (topic, msg, packet) {
      if (msg.is_success === 2) {
        this.visible.show_success_words = 1
      } else {
        this.visible.show_success_words = 2
      }
    },
    query_customer_by_sid (sid) {
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_customer,
        method: 'POST',
        data: {
          sid: sid
        },
        header: {
          // 'content-type': 'application/json' // 默认值
          'Content-Type': 'application/x-www-form-urlencoded' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          this.park = {
            total_spaces: d.list[0].mix_info.total_spaces || 100,
            parkname: d.list[0].alias_name || '车场'
          }
        } else {
          // reject();
        }
      })
    },
    randomP () {
      var z = '2345678ABCDEFGHIJKLMNPQRSTUWXYZ'
      var s = 'N'
      for (var k = 0; k < 7; k++) {
        var m = z[Math.floor(Math.random() * z.length)]
        s += m
      }
      return s
    },
    async doIn () {
      this.vh_p_number = this.randomP()
      // if (this.$Storejs.getItem('vh_p_number')) {
      //   this.$toast('您有未出场的无牌车辆')
      // }
      const action = 'mq_vh_inout_pnull_in'
      try {
        const client = await this.init_mqtt()
        await this.subscribe('mq_vh_inout_pnull_in')
        client.callback[action] = this.getResult
        const res = await axios({
          url: common.cloudParkingUrl + '/s2p/' + api.urlList.vh_inout_pnull_in,
          method: 'POST',
          data: {
            plate_number: this.vh_p_number,
            sid: this.$route.query.sid,
            channel_path: this.$route.query.channel_path,
            version: 'v2'
          },
          header: {
            // 'content-type': 'application/json' // 默认值
            'Content-Type': 'application/x-www-form-urlencoded' // 默认值
          }
        })
        const d = res && res.data
        if (d && d.code === common.resCode.OK) {
          localStorage.setItem('vh_p_number', this.vh_p_number)
          this.visible.success = true
        } else {
          this.park_req_msg = d.msg || '请求失败'
          this.visible.fail = true
        }
      } catch (e) {
        this.$toast.fail(e)
      }
    }
  },
  created () {
    this.create_this = this
  },
  mounted () {
    try {
      if (
        !this.$route.query.sid ||
        !this.$route.query.channel_path ||
        Number(this.$route.query.direction) !== 1
      ) {
        this.$toast('错误的二维码')
      }
      this.queryADConfig()
      this.query_customer_by_sid(this.$route.query.sid)
    } catch (e) {

    }
  },
  beforeDestroy () {
    this.init_mqtt().then((client) => {
      client.unsubscribe(['mq_vh_inout_pnull_in'])
    })
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 0.2rem;

  font-family: PingFangSC-Regular, sans-serif;
}

.null-title {
  padding-top: 0.42rem;
  font-size: 0.24rem;
  color: #333333;
  font-weight: bold;
}

.park-container {
  margin-top: 0.24rem;
  padding: 0.3rem 0.2rem;
  background: #f5f5f5;
}

.park-tips {
  font-size: 0.16rem;
  font-weight: bold;
  color: #999999;
}

.park-name {
  /*width: 3.18rem;*/
  margin-top: 0.3rem;
  height: 0.6rem;
  font-size: 0.18rem;
  text-align: center;
  line-height: 0.6rem;
  color: #4797ea;
  background: #f9f9f9;
}

.req-bnt {
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.18rem;
}

.dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  text-align: center;
  padding-top: 0.8rem;
  font-size: 0.18rem;
  font-weight: bold;
  background: white;
}

.p_null_img_box {
  margin: 0 auto;
  width: 0.8rem;
  height: 0.8rem;
}

.p_null_img_box img {
  width: 100%;
  height: 100%;
}

.req-success-word {
  margin-top: 0.2rem;
  font-size: 0.24rem;
  color: #333333;
}

.req-success-word.success {
  color: #55a532;
}

.req-success-word.fail {
  color: red;
}

.req-success-p {
  height: 0.24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /*line-height: .24rem;*/
  margin-top: 0.2rem;
  font-size: 0.2rem;
  color: #333333;
}

.success-b {
  display: inline-block;
  margin: 0 0 0 0.1rem;
  height: 0.18rem;
  line-height: 0.18rem;
  padding: 0.01rem 0.08rem;
  border-radius: 0.1rem;
  font-size: 0.12rem;
  color: #666666;
  background: #f4f4f4;
  text-align: center;
  vertical-align: middle;
}

.success-tips-word {
  margin-top: 0.1rem;
  font-size: 0.14rem;
  color: #999999;
}

.req-fail-word {
  margin-top: 0.1rem;
  font-size: 0.24rem;
  color: #333333;
}

.fail-btn-box {
  position: absolute;
  right: 0.2rem;
  bottom: 1rem;
  left: 0.2rem;
  /*padding: 0 .2rem;*/
  color: #4797ea;
  background: #edf5fd;
}

.tishi-line-1 {
  margin-top: 0.4rem;
}

.tishi-line-2 {
  margin-top: 0.2rem;
}

.tishi-btn-box {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -0.06rem 0.1rem #f7f7f7;
  /*background: red;*/
}

.tishi-btn-inner {
  width: 0.9rem;
}

.qry-fail-word {
  margin-top: 0.4rem;
  font-size: 0.26rem;
}

.qry-fail-word2 {
  margin-top: 0.4rem;
  font-size: 0.14rem;
  color: #898989;
}
</style>
